import Vue from "vue";

export default {
    getPaminelse: async (bolagerietFilter, clientId) => {
        try {
            const { data } = await Vue.axios.get(`invoices/toRemind1`, {
                params: {
                    bolageriet: bolagerietFilter,
                    clientId: clientId,
                },
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: att_atgarda.api.js ~ line 13 ~ error", error);
            return [];
        }
    },

    getPaminelse2: async (bolagerietFilter, clientId) => {
        try {
            const { data } = await Vue.axios.get(`invoices/toRemind2`, {
                params: {
                    bolageriet: bolagerietFilter,
                    clientId: clientId,
                },
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: att_atgarda.api.js ~ line 27 ~ error", error);
            return [];
        }
    },

    getUtskrifter: async (bolagerietFilter, clientId) => {
        try {
            const { data } = await Vue.axios.get(`invoices/utskrifter`, {
                params: {
                    bolageriet: bolagerietFilter,
                    clientId: clientId,
                },
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: att_atgarda.api.js ~ line 41 ~ error", error);
            return [];
        }
    },

    getInkasso: async (bolagerietFilter, clientId) => {
        try {
            const { data } = await Vue.axios.get(`invoices/inkasso`, {
                params: {
                    bolageriet: bolagerietFilter,
                    clientId: clientId,
                },
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: att_atgarda.api.js ~ line 55 ~ error", error);
            return [];
        }
    },

    getBevakas: async (bolagerietFilter, clientId) => {
        try {
            const { data } = await Vue.axios.get(`invoices/monitored`, {
                params: {
                    bolageriet: bolagerietFilter,
                    clientId: clientId,
                },
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: att_atgarda.api.js ~ line 69 ~ error", error);
            return [];
        }
    },

    sendInkasso: async ids => {
        try {
            await Vue.axios.post(`invoices/inkasso`, {
                ids,
            });
        } catch (error) {
            console.log("🚀 ~ file: att_atgarda.api.js ~ line 81 ~ error", error);
            return [];
        }
    },

    sendReminder1: async ids => {
        console.log("🚀 ~ file: att_atgarda.api.js ~ line 75 ~ ids", ids);
        try {
            await Vue.axios.post(`invoices/toRemind1`, {
                ids,
            });
        } catch (error) {
            console.log("🚀 ~ file: att_atgarda.api.js ~ line 81 ~ error", error);
        }
    },

    sendReminder2: async ids => {
        console.log("🚀 ~ file: att_atgarda.api.js ~ line 86 ~ ids", ids);
        try {
            await Vue.axios.post(`invoices/toRemind2`, {
                ids,
            });
        } catch (error) {
            console.log("🚀 ~ file: att_atgarda.api.js ~ line 92 ~ error", error);
        }
    },

    print: async ids => {
        try {
            await Vue.axios.post(`invoices/utskrifter`, {
                ids,
            });
            console.log("🚀 ~ file: att_atgarda.api.js ~ line 97 ~ ids", ids);
        } catch (error) {
            console.log("🚀 ~ file: att_atgarda.api.js ~ line 101 ~ error", error);
        }
    },

    sendToMonitorList: async ids => {
        console.log("🚀 ~ file: att_atgarda.api.js ~ line 140 ~ sendToMonitorList: ~ ids", ids);
        try {
            await Vue.axios.post(`invoices/monitored`, {
                ids,
            });
        } catch (error) {
            console.log("🚀 ~ file: att_atgarda.api.js ~ line 112 ~ error", error);
        }
    },

    removeFromMonitorList: async ids => {
        console.log("🚀 ~ file: att_atgarda.api.js ~ line 116 ~ ids", ids);
        try {
            await Vue.axios.delete(`invoices/monitored`, {
                data: {
                    ids,
                },
            });
        } catch (error) {
            console.log("🚀 ~ file: att_atgarda.api.js ~ line 124 ~ error", error);
        }
    },

    getCommentsBySourceId: async id => {
        try {
            const { data } = await Vue.axios.get(`invoices/comments`, {
                params: {
                    commentSource: 1,
                    sourceId: id,
                },
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: att_atgarda.api.js ~ line 145 ~ error", error);
        }
    },

    markAsPrinted: async ids => {
        try {
            await Vue.axios.put(`invoices/utskrifter/skickade`, {
                ids,
            });
            console.log("🚀 ~ file: att_atgarda.api.js ~ line 153 ~ ids", ids);
        } catch (error) {
            console.log("🚀 ~ file: att_atgarda.api.js ~ line 160 ~ error", error);
        }
    },
};
